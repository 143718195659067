<template>
  <div>
    <div class="row mt-3 mb-3">
      <h1 class="col-sm-12 text-right">Etapas</h1>
    </div>

    <Etapa :area="area" @update="obtener_etapas" />
  </div>
</template>

<script type="text/javascript">
  import Etapa from './Area/Detalle/Etapa'

  import apiMiArea from '@/apps/tickets/api/miarea';

  export default {
    components: {
      Etapa
    }
    ,data() {
      return {
        area: {
          id: null,
          etapas: []
        }
      }
    }
    ,mounted() {
      this.obtener_etapas();
    }
    ,methods: {
      async obtener_etapas() {
        try {
          this.area = (await apiMiArea.obtener_area()).data;
        }catch(e) {
          this.$log.error('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>